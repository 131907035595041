div.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #ecf1ff;
    border-radius: 4px;
    background-color: #ecf1ff;
}
div.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #4775ff;
    box-shadow: 0 0 0 2px rgba(71, 117, 255, 0.2);
    box-shadow: none;
}
div.ant-select-selector {
    outline: none;
}
div.ant-select.ant-select-open:hover,
div.ant-select.ant-select-open:focus {
    border-radius: 4px;
}
/* styled for selected option */
div.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #ecf1ff;
}
div.ant-select-dropdown {
    box-shadow: 0px 1.5px 6px #edf1fe;
}
