body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
div.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    width: 100%;
    height: 39px;
    font-size: 1rem;
}

div.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    line-height: 39px;
}
div.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 39px !important;
}
div.ant-select-selection-search {
    line-height: 39px;
}
div.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    height: 39px;
}

.sidebarWrapper {
    position: sticky;
    top: 40px;
    padding: 10px;
    max-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
}

.sideBarScrollList {
    padding: 0 20px;
    overflow-y: scroll;
    min-height: 80px;
}

.sideBarFooter {
    padding: 20px;
    background-color: white;
}
